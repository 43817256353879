import { Tab, Tabs } from "@material-ui/core";
import { TipoUsuario } from "api/enums/TipoUsuario";
import { DadosMarketShare, useLazyMarketShare } from "api/hooks";
import { TipoAgrupamentoMarketShare } from "api/hooks/useMarketShare/model/TipoAgrupamentoMarketShare";
import { TipoDadoIncluirMarketShareImpl } from "api/hooks/useMarketShare/model/TipoDadoIncluirMarketShare";
import { AgrupamentoRenavamQuery, FiltrosSelecionados } from "api/model";
import { Usuario } from "api/model/Usuario";
import { State } from "api/redux/model";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TipoRelatorioClipping } from "ui/components/ConfiguracaoClipping/enums";
import { TipoFormatoDadosRelatorio } from "ui/components/FormatoDadosRelatorio/model/TipoFormatoDadosRelatorio";
import ViewLayout from "ui/components/ViewLayout/ViewLayout";
import { RelatorioProps } from "ui/views/Props/RelatorioProps";
import { VisaoRelatorioMarketShare } from "./model/VisaoRelatorioMarketShare";
import { criaGraficoBarra } from "./util/criaGrafico";

const agrupamentoRenavam = new AgrupamentoRenavamQuery();
agrupamentoRenavam.comMarcaNome();
agrupamentoRenavam.comMarcaCor();
agrupamentoRenavam.comQuantidade();

const selecionaVisaoRelatorio = (
    setVisaoRelatorio,
    filtrosSelecionados,
    carregaDadosMarketShareDay,
    carregaDadosMarketShareMtd,
    carregaDadosMarketShareYtd
) => (event, visaoSelecionada) => {
    setVisaoRelatorio(visaoSelecionada);

    if (visaoSelecionada === VisaoRelatorioMarketShare.DAY) {
        carregaDadosMarketShareDay(filtrosSelecionados);
    } else if (visaoSelecionada === VisaoRelatorioMarketShare.MTD) {
        carregaDadosMarketShareMtd(filtrosSelecionados);
    } else if (visaoSelecionada === VisaoRelatorioMarketShare.YTD) {
        carregaDadosMarketShareYtd(filtrosSelecionados);
    }
};

const getTipoDadoIncluirMarketSharePorTipoGrafico = (visaoSelecionada: VisaoRelatorioMarketShare) => {
    if (visaoSelecionada === VisaoRelatorioMarketShare.DAY) {
        return [TipoDadoIncluirMarketShareImpl.DAY, TipoDadoIncluirMarketShareImpl.MTD];
    }
    if (visaoSelecionada === VisaoRelatorioMarketShare.MTD) {
        return [TipoDadoIncluirMarketShareImpl.MTD];
    }
    return [
        TipoDadoIncluirMarketShareImpl.MTD,
        TipoDadoIncluirMarketShareImpl.YTD,
        TipoDadoIncluirMarketShareImpl.YTD_PM
    ];
};

const criaConfiguracoesGraficoMktShare = (
    marketShare: DadosMarketShare | undefined,
    visaoRelatorioSelecionada: VisaoRelatorioMarketShare,
    tipoFormatoDadosRelatorio: TipoFormatoDadosRelatorio
) => {
    let categorias: string[] = [];
    let quantidades: number[] = [];
    let total = 0;

    if (!marketShare) {
        return {
            categorias,
            quantidades,
            total
        };
    }

    if (visaoRelatorioSelecionada === VisaoRelatorioMarketShare.DAY) {
        if (tipoFormatoDadosRelatorio === TipoFormatoDadosRelatorio.ABSOLUTO) {
            marketShare.chavesOrdendasPorDayDecrescente().forEach(categoria => {
                let volumeDia = marketShare.volumeDia(categoria);
                if (volumeDia > 0) {
                    categorias.push(categoria);
                    quantidades.push(volumeDia);
                }
            });
            total = marketShare.totalizador.volumeDia || 0;
        }
        if (tipoFormatoDadosRelatorio === TipoFormatoDadosRelatorio.MEDIA) {
            categorias = marketShare.chavesOrdendasPorMtdDecrescente();
            quantidades = categorias.map(categoria => marketShare.mediaDiaria(categoria));
            total = Number.parseFloat(
                quantidades.reduce((valorAnterior, valorAtual) => valorAnterior + valorAtual).toFixed(2)
            );
        }
    }
    if (visaoRelatorioSelecionada === VisaoRelatorioMarketShare.MTD) {
        categorias = marketShare.chavesOrdendasPorMtdDecrescente();
        if (tipoFormatoDadosRelatorio === TipoFormatoDadosRelatorio.ABSOLUTO) {
            quantidades = categorias.map(categoria => marketShare.volumeMtd(categoria));
            total = marketShare.totalizador.volumeMtd || 0;
        }
        if (tipoFormatoDadosRelatorio === TipoFormatoDadosRelatorio.MEDIA) {
            quantidades = categorias.map(categoria => marketShare.projecaoMensal(categoria));
            total = Number.parseFloat(
                quantidades.reduce((valorAnterior, valorAtual) => valorAnterior + valorAtual).toFixed(2)
            );
        }
    }
    if (visaoRelatorioSelecionada === VisaoRelatorioMarketShare.YTD) {
        categorias = marketShare.chavesOrdendasPorYtdDecrescente();
        if (tipoFormatoDadosRelatorio === TipoFormatoDadosRelatorio.ABSOLUTO) {
            quantidades = categorias.map(categoria => marketShare.volumeYtd(categoria));
            total = marketShare.totalizador.volumeYtd || 0;
        }
        if (tipoFormatoDadosRelatorio === TipoFormatoDadosRelatorio.MEDIA) {
            quantidades = categorias.map(categoria => marketShare.projecaoAnual(categoria));
            total = Number.parseFloat(
                quantidades.reduce((valorAnterior, valorAtual) => valorAnterior + valorAtual).toFixed(2)
            );
        }
    }

    let cores = categorias.map(categoria => `#${marketShare.itemMarketShare(categoria).corMarca}`);

    let valoresGrafico = quantidades.map(qtd => {
        return {
            y: qtd,
            percentage: (qtd * 100) / total
        };
    });

    return {
        categorias,
        quantidades,
        valoresGrafico,
        cores,
        total
    };
};

const aplicarFiltro = (carregaDadosMarketShare, setFiltrosSelecionados) => (filtro: FiltrosSelecionados) => {
    setFiltrosSelecionados(filtro);
    carregaDadosMarketShare(filtro);
};

const getDescricaoRelatorio = (usuario: Usuario) => {
    if (usuario.tipoUsuario === TipoUsuario.DEALER_GRUPO) {
        return "Market share dos principais concorrentes, com visões de Day, MTD e YTD. Como padrão vem filtrado apenas a área de atuação do dealer.";
    }
    return "Market share dos principais concorrentes, com visões de Day, MTD e YTD.";
};

const MarketShare: React.FC<RelatorioProps> = ({ filtro, viewLayoutProps }) => {
    const appState = useSelector((state: State) => state.app);
    const [visaoSelecionada, setVisaoSelecionada] = useState(VisaoRelatorioMarketShare.DAY);
    const [tipoFormatoDadosRelatorio, setTipoFormatoDadosRelatorio] = useState(TipoFormatoDadosRelatorio.ABSOLUTO);
    const [dadosRelatorio, setDadosRelatorio] = useState<Map<VisaoRelatorioMarketShare, DadosMarketShare>>(new Map());
    const [filtrosSelecionados, setFiltrosSelecionados] = useState<FiltrosSelecionados>();

    const [
        carregaDadosMarketShareDay,
        { carregandoDadosMarketShare: carregandoDadosMarketShareDay }
    ] = useLazyMarketShare({
        tipoAgrupamentoMarketShare: TipoAgrupamentoMarketShare.MARCA_NOME,
        dadosDisponiveisNoMarketShare: getTipoDadoIncluirMarketSharePorTipoGrafico(VisaoRelatorioMarketShare.DAY),
        agrupamentoRenavam,
        aposCarregar: dadosMarketShare => {
            const novoDadosRelatorio = new Map(dadosRelatorio);
            novoDadosRelatorio.set(VisaoRelatorioMarketShare.DAY, dadosMarketShare);
            setDadosRelatorio(novoDadosRelatorio);
        }
    });

    const [
        carregaDadosMarketShareMtd,
        { carregandoDadosMarketShare: carregandoDadosMarketShareMtd }
    ] = useLazyMarketShare({
        tipoAgrupamentoMarketShare: TipoAgrupamentoMarketShare.MARCA_NOME,
        dadosDisponiveisNoMarketShare: getTipoDadoIncluirMarketSharePorTipoGrafico(VisaoRelatorioMarketShare.MTD),
        agrupamentoRenavam,
        aposCarregar: dadosMarketShare => {
            const novoDadosRelatorio = new Map(dadosRelatorio);
            novoDadosRelatorio.set(VisaoRelatorioMarketShare.MTD, dadosMarketShare);
            setDadosRelatorio(novoDadosRelatorio);
        }
    });

    const [
        carregaDadosMarketShareYtd,
        { carregandoDadosMarketShare: carregandoDadosMarketShareYtd }
    ] = useLazyMarketShare({
        tipoAgrupamentoMarketShare: TipoAgrupamentoMarketShare.MARCA_NOME,
        dadosDisponiveisNoMarketShare: getTipoDadoIncluirMarketSharePorTipoGrafico(VisaoRelatorioMarketShare.YTD),
        agrupamentoRenavam,
        aposCarregar: dadosMarketShare => {
            const novoDadosRelatorio = new Map(dadosRelatorio);
            novoDadosRelatorio.set(VisaoRelatorioMarketShare.YTD, dadosMarketShare);
            setDadosRelatorio(novoDadosRelatorio);
        }
    });

    let carregaDadosMarketShare;
    if (visaoSelecionada === VisaoRelatorioMarketShare.DAY) {
        carregaDadosMarketShare = carregaDadosMarketShareDay;
    } else if (visaoSelecionada === VisaoRelatorioMarketShare.MTD) {
        carregaDadosMarketShare = carregaDadosMarketShareMtd;
    } else if (visaoSelecionada === VisaoRelatorioMarketShare.YTD) {
        carregaDadosMarketShare = carregaDadosMarketShareYtd;
    }

    useEffect(() => {
        if (filtro) {
            aplicarFiltro(carregaDadosMarketShare, setFiltrosSelecionados)(filtro);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ViewLayout
            titulo="Market Share"
            descricao={getDescricaoRelatorio(appState.usuarioLogado)}
            carregando={carregandoDadosMarketShareDay || carregandoDadosMarketShareMtd || carregandoDadosMarketShareYtd}
            filtroProps={{
                periodo: false,
                aplicarFiltro: aplicarFiltro(carregaDadosMarketShare, setFiltrosSelecionados)
            }}
            clippingProps={{
                tipoRelatorio: TipoRelatorioClipping.MARKET_SHARE,
                filtrosSelecionados
            }}
            formatoDadosRelatorioProps={{
                onSelect: setTipoFormatoDadosRelatorio,
                percentual: false,
                evolucao: false
            }}
            {...viewLayoutProps}>
            <Tabs
                textColor="secondary"
                centered={true}
                value={visaoSelecionada}
                onChange={selecionaVisaoRelatorio(
                    setVisaoSelecionada,
                    filtrosSelecionados,
                    carregaDadosMarketShareDay,
                    carregaDadosMarketShareMtd,
                    carregaDadosMarketShareYtd
                )}
                aria-label="disabled tabs example"
                variant="fullWidth">
                <Tab label="Day" value={VisaoRelatorioMarketShare.DAY} />
                <Tab label="MTD" value={VisaoRelatorioMarketShare.MTD} />
                <Tab label="YTD" value={VisaoRelatorioMarketShare.YTD} />
            </Tabs>

            <HighchartsReact
                highcharts={Highcharts}
                options={criaGraficoBarra(
                    criaConfiguracoesGraficoMktShare(
                        dadosRelatorio.get(visaoSelecionada),
                        visaoSelecionada,
                        tipoFormatoDadosRelatorio
                    )
                )}
            />
        </ViewLayout>
    );
};

export default MarketShare;
