const config = {
    development: {
        audi: {
            url: "http://localhost:9080",
            client: "audi"
        },
        abraland: {
            url: "http://localhost:9080",
            client: "abraland"
        },
        bmw: {
            url: "http://localhost:9080",
            client: "bmw"
        },
        harley: {
            url: "http://localhost:9080",
            client: "harley"
        },
        triumph: {
            url: "http://localhost:9080",
            client: "triumph"
        },
        vwco: {
            url: "http://localhost:9080",
            client: "vwco"
        },
        bajaj: {
            url: "http://localhost:9080",
            client: "bajaj"
        }
    },
    homolog: {
        audi: {
            url: "https://emplacar-parent-3-6.ciaudibrasil.com.br",
            client: "audi"
        },
        abraland: {
            url: "https://emplacar-parent-3-6.portalabraland.com.br",
            client: "abraland"
        },
        bmw: {
            url: "https://emplacar-parent-3-6.bmwbic.com.br",
            client: "bmw"
        },
        harley: {
            url: "https://harley.emplacarapp.tecsinapse.com.br",
            client: "harley"
        },
        triumph: {
            url: "https://emplacar-parent-3-6.portaltriumphbrasil.com.br",
            client: "triumph"
        },
        vwco: {
            url: "https://vwco.emplacarapp.tecsinapse.com.br",
            client: "vwco"
        },
        bajaj: {
            url: "https://bajajhomolog.emplacar.tecsinapse.com.br",
            client: "bajaj"
        }
    },
    production: {
        "https://emplacarapp.ciaudibrasil.com.br": {
            url: "https://emplacamentoaudi.portaltecsinapse.com.br",
            client: "audi"
        },
        "https://emplacarapp.portalabraland.com.br": {
            url: "https://emplacamentoabraland.portaltecsinapse.com.br",
            client: "abraland"
        },
        "https://emplacarapp.bmwbic.com.br": {
            url: "https://emplacamentobmw.portaltecsinapse.com.br",
            client: "bmw"
        },
        "https://emplacarapp.portalhdb.com.br": {
            url: "https://emplacamentohdb.portaltecsinapse.com.br",
            client: "harley"
        },
        "https://emplacarapp.portaltriumphbrasil.com.br": {
            url: "https://emplacamentotriumph.portaltecsinapse.com.br",
            client: "triumph"
        },
        "https://emplacarapp.portalman.com.br": {
            url: "https://vwco.emplacar.tecsinapse.com.br",
            client: "vwco"
        },
        "https://app.bajaj.emplacar.tecsinapse.com.br": {
            url: "https://bajaj.emplacar.tecsinapse.com.br",
            client: "bajaj"
        }
    }
};

export const getClientConfig = (origin, environment) => {
    const clientConfigs = config[environment];

    if (environment === "production") {
        return clientConfigs[origin];
    }

    const baseUrl = origin.replace("http://", "").replace("https://", "");
    const firstDot = baseUrl.indexOf(".");
    const client = baseUrl.substr(0, firstDot);

    return clientConfigs[client];
};
